


const ModalItem = ({ immagine }) => {

    const onClick = (e) => {
        e.stopPropagation(); // previene che l'evento onClick del parent chiuda il modal
        //e.preventDefault();
        console.log(`immagine: ${immagine}`);
    }

    return (
        <div className="modalItem" onClick={onClick}>
            Immagine: {immagine}
            <br />
            <div className="imgContainer">
                <img src={(typeof immagine === "string" && immagine !== "") ? require(`../../data/games/${immagine}.jpg`) : './null.jpg'} alt="no_image"></img>
            </div>

        </div >
    )


}

export default ModalItem;