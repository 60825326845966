import {  useRef } from 'react';

// Css
import '../../styles/container/ricercaContainer.css';

const ItemRicerca = ({ change, filtro }) => {
    // console.log('load: ricerca');
    
     const inputRicerca = useRef();
    //  if (filtro && filtro!==undefined){
    //     inputRicerca.current.value=filtro;
    //  }

    // useEffect(() => {
    //     console.log('mount: ricerca');

       

    //     return () => {            
    //         console.log('onMount: ricerca');
    //     }
    // },[]);

    return (
        <>
            {/* <div Style="background: gray;display: flex;padding:10px"> */}
            <div className="ricercaContainer">
                <div>Ricerca: {filtro}</div>
                <input id="inputRicerca" onChange={change} ref={inputRicerca}></input>
            </div>
        </>
    )

}

export default ItemRicerca;