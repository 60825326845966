// CSS
import '../../styles/sidebar/sidebar.css';

import {Link} from 'react-router-dom';

const SidebarItemLink = ({path,label})=>{
    return (
        <>
           <div className='sidebarItem' >
            <Link to={path}>{label}</Link>
           </div>
        </>
    )
}

export default SidebarItemLink;