import '../../styles/header/header.css';

const Header = () => {

    return (
        <>
            <div className="header">My favorite games</div>
        </>
    )

}

export default Header;