import '../../styles/modal/modal.css';
import ModalItem from './modal_item';

const Modal = (props)=>{

    return(
        <>
        <div className={props.visible?"modal-visible":"modal"} onClick={props.onClick}>
            <ModalItem immagine={props.immagine} />
        </div>
        </>
    )

}

export default Modal;