// Components
import SingleItem from './singleItem.js';

// Css
import  '../../../styles/container/games/itemContainer.css';

const funzione = (data, filtro, apriModal) => {
// Crea un altro array filtrando rispetto a quello di origine (data)
    let filtered = data.filter(e=>e.desc.toLowerCase().includes(filtro));
    
    // Per ogni elemento dell'array filtrato (che al max corrisponde a quello di origine) restituisce il componente
    return filtered.map(e=><SingleItem key={e.id} data={e} apriModal={()=>{apriModal(e.desc)}} />);
}

const Items = ({ data, filtro, apriModal }) => {
    return (
        <>
        <div className="itemContainer">
            {funzione(data, filtro, apriModal)}
        </div>
        </>
    )
}

export default Items;