// CSS
import '../../../styles/container/songs/index.css';

const SongItem = ({ item }) => {
    return (
        <>
            <div className="songItem">
                <div className='infoContainer'>
                    <h3>{item.name}</h3>
                    <div > {item.artist}</div>
                </div>
                <div className='dateContainer'>
                    {item.age}
                </div>
            </div>
        </>
    )
}

export default SongItem;