// Cmponenti


// Styles
import '../../styles/sidebar/sidebar.css';

const Sidebar = ({children}) => {

    return (
        <>
            <div className='sidebar'>
                {children}
            </div>
        </>
    )
}

export default Sidebar;