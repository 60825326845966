// Direttive di react
import React, { useState } from 'react'; // useState permette di utilizzare le funzioni di react in un componente function based
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Componenti interni
import Header from './components/header/header';
import Sidebar from './components/sidebar/sidebar';
import Container from './components/container/container';
//import SidebarItem from './components/sidebar/sidebarItem';
import SidebarItemLink from './components/sidebar/sidebarItemLink';
import Modal from './components/modal/modal_container';

import ItemRicerca from './components/container/itemRicerca';
import Items from './components/container/games/items';

import SongContainer from './components/container/songs/songContainer';

// Context
import { useContext } from 'react';
import { MyContext } from './context';


//Importazione css
import './styles/style.css';

// Dati fittizi per simulare una call server-side
//import DATA from './data/data.json';
import GAMES from './data/games.json';

// Componente App principale importato poi nell'index
const App = () => {

    // Getione degli stati: per ogni variabile/ogngetto che si vuol gestire è necessario definire anche il suo metodo di update
    let [ricerca, setRicerca] = useState(''); //Il valore di "ricerca" ha come default ''
    let [modalVisible, setModalVisible] = useState(false); //Visiilità modale
    let [modalImmagine, setModalImmagine] = useState(""); //Immagine modale

    const provider = useContext(MyContext);

    // Definisce quale menù usare della sidebar
    let [menuIndex, setMenuIndex] = useState(1);

    // evento onChange dell'input passato al componente Input
    const inputOnChange = (e) => {

        // 1° Metodo: richiamo semplicemente
        setRicerca(ricerca = e.target.value)

        // 2° Metodo: utilizzo il riferimento
        // setRicerca((r) => { // r corrisponde alla variabile "count"
        //     r = e.target.value;
        //     return r; // è necessario il return
        // });
    }

    const onClickTest = (immagine) => {

        setModalVisible(!modalVisible);

        // Quando clicco sull'item l'immagine passata è appunto una stringa
        // Quando clicco fuori dal modal per uscire, l'immagine non è più una stringa
        if (typeof immagine === "string") {
            setModalImmagine(immagine);
        }

    }

    // Definisce quale container centrale usare in base al menù della Sidebar
    const setMenu = () => {
        switch (menuIndex) {
            case 1:
                return (
                    <>
                        {/* Container: questo componente a sua volta contiene altri componenti interni detti "children" */}
                        {/* Nel render del componente va specificato che dovrà renderizzare anche i children all'interno */}
                        <Container>
                            {/* Il seguente input riceve in ingresso sulla proprietà "change" la funzione "inputOnChange" che verrà attribuita al vero input html nell'evento onChange */}
                            <ItemRicerca change={inputOnChange} filtro={ricerca} />
                            <Items data={GAMES} filtro={ricerca} apriModal={onClickTest} />
                            {/* <div onClick={onClickTest}>Cliccca per modal popup</div> */}
                        </Container>
                    </>
                );

            case 2:
                return (<>
                    <Container>
                        <ItemRicerca change={(e) => { provider.setSongFiltro(e.target.value) }} filtro={provider.songFiltro} />
                        <SongContainer></SongContainer>
                    </Container>
                </>);

            default:
                return (<></>);

        }
    }

    const changeMenu = (index) => {
        setMenuIndex(index);
    }

    // Risultato della funzione
    return (
        // fragment come parent element al posto del div
        <>
            {/* Importazione di componenti esterni */}
            <Header />
            <BrowserRouter>
                <Sidebar>
                    {/* <SidebarItem name={'Games'} changeMenu={changeMenu} menuIndex={1} />
                    <SidebarItem name={'Song'} changeMenu={changeMenu} menuIndex={2} />
                    <SidebarItem name={'To do..'} changeMenu={changeMenu} menuIndex={0} /> */}
                    <SidebarItemLink path="" label="Home" />
                    <SidebarItemLink path="games" label="Games" />
                    <SidebarItemLink path="songs" label="Songs" />
                </Sidebar>


                <Routes>
                    <Route path="/" element={
                        <>
                            Home
                        </>
                    }></Route>

                    <Route path="games" element={
                        <>
                            {/* Container: questo componente a sua volta contiene altri componenti interni detti "children" */}
                            {/* Nel render del componente va specificato che dovrà renderizzare anche i children all'interno */}
                            <Container>
                                {/* Il seguente input riceve in ingresso sulla proprietà "change" la funzione "inputOnChange" che verrà attribuita al vero input html nell'evento onChange */}
                                <ItemRicerca change={inputOnChange} filtro={ricerca} />
                                <Items data={GAMES} filtro={ricerca} apriModal={onClickTest} />
                                {/* <div onClick={onClickTest}>Cliccca per modal popup</div> */}
                            </Container>
                        </>
                    } />

                    <Route path="songs" element={
                        <>
                            <Container>
                                <ItemRicerca change={(e) => { provider.setSongFiltro(e.target.value) }} filtro={provider.songFiltro} />
                                <SongContainer></SongContainer>
                            </Container>
                        </>
                    } />

                </Routes>
            </BrowserRouter>

            {/* Il menù centrale varia in base ad una variabile sttao menùIndex che viene settata dai pulsanti */}
            {/* {setMenu()} */}

            <Modal visible={modalVisible} onClick={onClickTest} immagine={modalImmagine} />
        </>
    )

}

export default App;