import React, { Component } from "react";
import SONGS from '../data/songs.json';

const MyContext = React.createContext();



class MyProvider extends Component {

    state = {
        songs: SONGS,
        songFiltro: ''
    }

    setSongFiltro = (filtro) => {
        //console.log(filtro);
        this.setState({...this.state, songFiltro: filtro });
    }

    render() {
        return (
            <>
                <MyContext.Provider value={{
                    songs: this.state.songs,
                    songFiltro: this.state.songFiltro,
                    setSongFiltro: this.setSongFiltro
                }}>
                    {this.props.children}
                </MyContext.Provider>
            </>
        )
    };

}

export { MyProvider, MyContext };