// Direttive di react
import React from 'react';
import ReactDOM from 'react-dom/client'; // queta in particolare serve per la costruzione del DOM

// Il componente app è a parte
import App from './app';

import { MyProvider } from './context';

// const App = () => (
//     React.createElement('h1',{className: 'Title'},'ciaoo')
// )

const root = ReactDOM.createRoot(document.getElementById('root')); // un div root è presente nell'index html
root.render(
    <MyProvider>
        <App />
    </MyProvider>
);