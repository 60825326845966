// Css
import '../../../styles/container/songs/index.css';

// Components
import SongItem from './songItem';

// Provider
import { useContext } from 'react';
import { MyContext } from '../../../context';

const SongContainer = () => {

    const provider = useContext(MyContext);

    const visualizzaElementi = () => {
        const filtrati = provider.songs.filter((el) => {
            let result = true;

            if (el.name) {
                result = el.name.toLowerCase().includes(provider.songFiltro);
            }
            if (el.artist) {
                result = el.artist.toLowerCase().includes(provider.songFiltro);
            }
            if (el.age) {
                result = el.age.toString().toLowerCase().includes(provider.songFiltro);
            }

            return result;
        }
        );

        return filtrati.map((item, i) => <SongItem key={i} item={item} />)
    }

    return (
        <>
            <div className='songContainer'>
                {visualizzaElementi()}
            </div>
        </>
    )
}

export default SongContainer;