import '../../../styles/container/games/singleItem.css';

// const onClick = (e)=>{
//     console.log(`Ciao: ${e.target.innerText}`);
//     // visualizza immagine
// }


const SingleItem = ({ data, apriModal }) => (
    <>
        <div className='singleItem' onClick={apriModal}>
            <h1 >{data.desc}</h1>
            <h3>{data.dns}</h3>
            <img src={(typeof data.desc === "string" && data.desc !== "") ? require(`../../../data/games/${data.desc}.jpg`) : './null.jpg'} alt="no_image"></img>
        </div>
    </>
)


export default SingleItem;