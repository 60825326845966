import '../../styles/container/container.css';

// const containerOnClick = (e) => {
//     console.log(`container: e.target.value`);
// }

const Container = ({  children }) => (
    <>
        <div className='container' >
            {/* Elementi interni al seguente parent (sono l'input e il div definiti nell'app.js) */}
            {children}
        </div>
    </>
);

export default Container;